import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { CustomerResource, DeliveryNoticeList, DeliveryNoticeListQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  showWarningConfirm,
  translation,
  dateFormat,
  downloadFileByBlob,
  debounce,
  showWarningConfirmLoading
} from '@/utils';
import { Message } from 'element-ui';
import { DeliveryStatusEnum } from '@/resource/enum';
import { customerService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { deliveryNoticeService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';

@Component({
  name: 'DeliveryNotice'
})
export default class DeliveryNotice extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<DeliveryNoticeList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<DeliveryNoticeList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'deliveryNoticeList.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'supplierName',
      label: 'deliveryNoticeList.supplier',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'projectName',
      label: 'deliveryNoticeList.project',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'deliveryNoticeList.customer',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'status',
      label: 'deliveryNoticeList.status',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      showOverflowTooltip: true,
      sortable: 'custom',
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).requiredDeliveryTime);
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).requiredArriveTime);
      }
    },
    {
      prop: 'shopTotal',
      label: 'deliveryNoticeList.shopTotal',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemTotal',
      label: 'deliveryNoticeList.itemTotal',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'deliveryRemark',
      label: 'deliveryNoticeList.deliveryRemark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as DeliveryNoticeList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<DeliveryNoticeListQuery>> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'deliveryNoticeList.inputCode'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'deliveryNoticeList.status',
      option: {
        placeholder: 'deliveryNoticeList.selectStatus'
      },
      optionData: deliveryNoticeService.getStatus
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'deliveryNoticeList.customer',
      option: {
        placeholder: 'deliveryNoticeList.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'deliveryNoticeList.orderCreateTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'deliveryNoticeList.batchPush',
      operationType: 'push',
      disabled: true,
      icon: 'el-icon-s-promotion',
      permissionCode: 'delivery:notice:batchPush',
      handleClick: this.batchPushDeliveryNotice
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'deliveryNoticeList.batchCancel',
      operationType: 'cancel',
      disabled: true,
      plain: true,
      icon: 'el-icon-close',
      permissionCode: 'delivery:notice:batchCancel',
      handleClick: this.batchCancelDeliveryNotice
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'delivery:notice:batchDelete',
      plain: true,
      disabled: true,
      handleClick: this.batchDeleteDeliveryNotice
    },
    {
      slot: 'start',
      operationType: 'more'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<DeliveryNoticeList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'delivery:notice:edit',
        dynamicHidden: (rowData: DeliveryNoticeList): boolean => {
          return rowData.status !== DeliveryStatusEnum.new;
        },
        handleClick: (item: DeliveryNoticeList): void => {
          this.$router.push({
            path: 'add-delivery-notice',
            query: {
              id: item.id.toString()
            }
          });
        }
      }
    ]
  };

  public deliveryNotice: DeliveryNoticeList | null = null;

  public customers: Array<CustomerResource> = [];

  public selectedRows: Array<DeliveryNoticeList> = [];

  private queryForm: Partial<DeliveryNoticeListQuery> = {
    createUserName: undefined,
    customerId: undefined,
    keywords: undefined,
    createEndTime: undefined,
    createStartTime: undefined,
    status: undefined,
    itemId: undefined
  };

  private sortOptions: SortOptions<DeliveryNoticeList> = this.tableOption.defaultSort!;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'delivery-notice');
    this.getCustomers();
    this.loadData();
  }

  public activated(): void {
    if (this.$route.query.itemId) {
      // 路由携带查询条件参数，就路由的为主
      Object.assign(this.queryForm, {
        itemId: (this.$route.query.itemId as string) || ''
      });
      this.$router.push({ query: {} });
    }
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<DeliveryNoticeList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(deliveryNotice: DeliveryNoticeList): void {
    this.$router.push({
      path: 'delivery-notice-details',
      query: {
        id: deliveryNotice.id.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<DeliveryNoticeList>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: DeliveryNoticeList): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public getStatusClass(deliveryNotice: DeliveryNoticeList): string {
    switch (deliveryNotice.status) {
      case DeliveryStatusEnum.new:
        return 'info-dot';
      case DeliveryStatusEnum.waitTakeOrder:
        return 'primary-dot';
      case DeliveryStatusEnum.waitDelivery:
        return 'danger-dot';
      // case DeliveryStatusEnum.partDelivery:
      //   return 'warn-dot';
      // case DeliveryStatusEnum.delivered:
      //   return 'warn-dot';
      case DeliveryStatusEnum.complete:
        return 'success-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(deliveryNotice: DeliveryNoticeList): string {
    return `deliveryNoticeStatus.${DeliveryStatusEnum[deliveryNotice.status]}`;
  }

  public moreClick(command: 'exportPop' | 'exportPoster'): void {
    switch (command) {
      case 'exportPop':
        this.exportPop();
        break;
      case 'exportPoster':
        this.exportPoster();
        break;
      default:
        break;
    }
  }

  private async batchDeleteDeliveryNotice(): Promise<void> {
    showWarningConfirmLoading(translation('tip.confirmDelete'), async (done: any) => {
      try {
        const idList: Array<number> = this.selectedRows.filter(x => x.status === DeliveryStatusEnum.new).map(x => x.id);
        if (idList.length === 0) {
          Message.warning(translation('deliveryNoticeList.operationNoStandard'));
          this.clearSelection();
          return;
        }
        await deliveryNoticeService.batchDelete(idList);
        Message.success(translation('operationRes.deleteSuccess'));
      } catch (error) {
        messageError(error);
      } finally {
        this.reloadData();
        done();
      }
    })
      .then(() => {})
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchPushDeliveryNotice(): Promise<void> {
    showWarningConfirm(translation('deliveryNoticeList.confirmPush'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === DeliveryStatusEnum.new)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('deliveryNoticeList.operationNoStandard'));
            this.clearSelection();
            return;
          }
          await deliveryNoticeService.batchPush(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  private async batchCancelDeliveryNotice(): Promise<void> {
    showWarningConfirm(translation('deliveryNoticeList.confirmCancel'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows
            .filter(x => x.status === DeliveryStatusEnum.waitTakeOrder)
            .map(x => x.id);
          if (idList.length === 0) {
            Message.warning(translation('deliveryNoticeList.onlyWaitTakeOrder'));
            this.clearSelection();
            return;
          }
          await deliveryNoticeService.batchCancel(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }

  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    deliveryNoticeService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<DeliveryNoticeList>): void {
    this.operationOptions.forEach(x => {
      x.disabled = value.length === 0;
    });
  }

  private clearSelection(): void {
    (this.$refs.deliveryNoticeTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private exportPop(): void {
    if (this.selectedRows.length !== 1) {
      Message.warning(translation('deliveryNoticeList.exportMaxTip'));
      return;
    }
    this.tableOption.loading = true;
    deliveryNoticeService
      .exportPop(this.selectedRows[0].id)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('deliveryNoticeList.popFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private exportPoster(): void {
    this.tableOption.loading = true;
    deliveryNoticeService
      .exportPoster(this.selectedRows.map(x => x.id))
      .then((blob: any) => {
        downloadFileByBlob(`${translation('deliveryNoticeList.posterFileName')}_${dateFormat(new Date())}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }
}
